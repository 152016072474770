<!-- 红包记录 -->
<template>
  <page-container class="redEnvelopesHome">
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <template #loading>
        {{ pagination.pageNum < 2 ? '' : '加载中...' }}
      </template>

      <!-- 更多列表加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedTextRender()"
          @load="onLoad"
      >
        <!-- 红包 -->
        <div
            v-for="item of dataList"
            class="redEnvelopesBox"
        >
          <div class="tradeName">
            <div class="goods">
              <span class="title">商铺名称:</span>
              <span class="text">{{ item.groupName }}</span>
            </div>
            <div class="status">{{ item.type == 1 ? '发放' : '领取' }}</div>
          </div>
          <div class="redEnvelopesQuantity">
            <div class="redEnvelopesPrice">
              <span class="title">金额:</span>
              <span class="text">{{ item.amount }}元</span>
            </div>
            <div class="redEnvelopesTime">
              <span class="title">创建时间:</span>
              <span class="text">{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </page-container>
</template>

<script>
import { getUserRed } from '@/api/user.js';

export default {
  name: 'redPacket',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getUserRed({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },
  },
};
</script>

<style lang="less">
.redEnvelopesHome {
  width: 100%;
  height: auto;
  background-color: #FFFFFF;

  .redEnvelopesBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto 15px auto;
    padding-bottom: 10px;
    width: 90%;
    height: 80px;
    border-bottom: 1px solid #EEEEEE;

    .tradeName {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      .goods {
        .title {
          font-size: 15px;
          font-weight: 600;
        }

        .text {
          font-size: 13px;
        }
      }

      .status {
        font-size: 15px;
        color: @primary-color;
      }

    }

    .redEnvelopesQuantity {
      display: flex;
      justify-content: space-between;

      .redEnvelopesPrice {
        .title {
          font-size: 15px;
          font-weight: 600;
        }

        .text {
          font-size: 13px;
        }
      }

      .redEnvelopesTime {
        .title {
          font-size: 15px;
          font-weight: 600;
        }

        .text {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
